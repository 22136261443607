import { createInertiaApp, InertiaHead, PageResolver } from '@inertiajs/inertia-react';
import { InertiaProgress } from '@inertiajs/progress';
import { MainLayout } from 'App/Layouts/MainLayout';
import cls from 'classnames';
import * as React from 'react';
import { createElement, StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { toast, ToastContainer } from 'react-toastify';
import { FormErrorContextProvider, InitialApplicationContext } from 'Shared/Support/Contexts';
import { pushNotificationHelper } from 'Shared/Support/pushNotificationHelper';
import 'Shared/Support/yupExtensions';

const toastContextClass = {
  success: 'bg-green-500',
  error: 'bg-red-600',
  info: 'bg-gray-600',
  warning: 'bg-orange-400',
  default: 'bg-indigo-600',
  dark: 'bg-white-600 font-gray-300',
};

export const initErrorTracking = async () => {
  if (window.sentryDsn) {
    const Sentry = await import('@sentry/react');

    Sentry.init({
      dsn: window.sentryDsn,
      integrations: [new Sentry.BrowserTracing()],
      environment: window.sentryEnvironment,
      tracesSampleRate: window.sentryTracesSampleRate,
    });
  }
};

export const initInertiaApp = ({ resolve }: { resolve: PageResolver }) => {
  InertiaProgress.init({
    showSpinner: true,
  });
  initErrorTracking();
  return createInertiaApp<Window['inertiaInitialPageData']['props']>({
    resolve,
    page: window.inertiaInitialPageData,
    setup({ el, App, props }) {
      createRoot(el).render(
        <StrictMode>
          <ToastContainer
            autoClose={3000}
            closeButton={false}
            pauseOnFocusLoss
            pauseOnHover
            theme="colored"
            position="top-center"
            toastClassName={({ type }) =>
              cls(toastContextClass[type || 'default'], 'relative flex p-1 min-h-10 rounded justify-between overflow-hidden cursor-pointer')
            }
          />
          <InitialApplicationContext.Provider value={{ application: props.initialPage.props.application }}>
            <App {...props}>
              {({ key, props, Component }) => {
                const page = createElement(Component, { key, ...props });
                const user = props.application!.user;
                useEffect(() => {
                  if (Array.isArray(page.props.flash)) {
                    page.props.flash.forEach(({ type, message, key }) => {
                      toast(message, {
                        type,
                        toastId: key,
                      });
                    });
                  }
                }, [JSON.stringify(page.props.flash)]);

                useEffect(() => {
                  pushNotificationHelper(user).catch((err) => {});
                }, []);

                const Layout = Component.layout || MainLayout;

                return (
                  <FormErrorContextProvider>
                    <InertiaHead key={page.props.page_title}>
                      <title>{(page.props.page_title ? `${page.props.page_title} | ` : '') + page.props.application.site_name}</title>
                    </InertiaHead>
                    <Layout>{page}</Layout>
                  </FormErrorContextProvider>
                );
              }}
            </App>
          </InitialApplicationContext.Provider>
        </StrictMode>
      );
    },
  });
};
