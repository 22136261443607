import { axios } from '@webfox/webfox-ui';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';
import route from 'ziggy-js';

declare global {
  interface Window {
    firebaseConfig: {
      firebase_api_key: string;
      firebase_auth_domain: string;
      firebase_project_id: string;
      firebase_storage_bucket: string;
      firebase_messaging_sender_id: string;
      firebase_app_id: string;
      firebase_measurement_id: string;
      firebase_vapid_key: string;
    };
  }
}

export async function pushNotificationHelper(user) {
  if (!('Notification' in window)) {
    console.log('This browser does not support notifications');
    return;
  }

  const config = {
    apiKey: window.firebaseConfig.firebase_api_key,
    authDomain: window.firebaseConfig.firebase_auth_domain,
    projectId: window.firebaseConfig.firebase_project_id,
    storageBucket: window.firebaseConfig.firebase_storage_bucket,
    messagingSenderId: window.firebaseConfig.firebase_messaging_sender_id,
    appId: window.firebaseConfig.firebase_app_id,
    measurementId: window.firebaseConfig.firebase_measurement_id,
  };

  const messaging = await (async () => {
    try {
      const isSupportedBrowser = await isSupported();
      if (isSupportedBrowser) {
        initializeApp(config);
        return getMessaging();
      }
      console.log('Firebase not supported this browser');
      return null;
    } catch (err) {
      console.log(err);
      return null;
    }
  })();

  if (!messaging) {
    console.log('Firebase not supported this browser');
    return;
  }

  if (Notification.permission === 'granted') {
    getToken(messaging, { vapidKey: window.firebaseConfig.firebase_vapid_key })
      .then(
        (token?: string) => token && axios.put(route('user.device-token'), { device_token: token }).then(() => console.log('device token updated'))
      )
      .catch((err) => {});
  } else {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        getToken(messaging, { vapidKey: window.firebaseConfig.firebase_vapid_key })
          .then(
            (token?: string) => token && axios.put(route('user.device-token'), { device_token: token }).then(() => console.log('device token updated'))
          )
          .catch((err) => {});
      }
    });
  }

  // onMessage(messaging, async (payload) => {
  //   if (!user?.settings?.push_notifications) return;
  //   if (payload.notification?.title && payload.notification.body && (await Notification.requestPermission()) === 'granted') {
  //     const body = JSON.parse(payload.notification.body);
  //
  //     new Notification(payload.notification.title, {
  //       body: body.message,
  //       data: body?.data,
  //       icon: 'android-logo-192x192.png',
  //     });
  //   }
  // });
}
